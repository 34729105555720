<template>
  <v-app>
    <page-header />
    <page-content />
    <page-footer />
  </v-app>
</template>

<script>
export default {
  name: "Page",
  components: {
    PageHeader: () => import("./Header"),
    PageContent: () => import("./Content"),
    PageFooter: () => import("./Footer"),
  },
};
</script>
<style lang="scss" scoped>
.v-application {
  line-height: 1;
}
</style>
